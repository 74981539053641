export const getAllCountries = () => {
  return fetch(`${process.env.EDGE_CONFIG}/item/countryList?token=${process.env.EDGE_CONFIG_TOKEN}`)
}

export const getAllStates = (state: 'USStatesList' | 'CAStatesList') => {
  return fetch(`${process.env.EDGE_CONFIG}/item/${state}?token=${process.env.EDGE_CONFIG_TOKEN}`)
}

export const getConfigByKeyName = (
  name: 'countryList' | 'USStatesList' | 'CAStatesList' | 'ArcadeGameUsers' | 'CommonPasswordList'
) => {
  return fetch(`${process.env.EDGE_CONFIG}/item/${name}?token=${process.env.EDGE_CONFIG_TOKEN}`)
}

export const getArcadeGameUsers = () => {
  return fetch(
    `${process.env.EDGE_CONFIG}/item/ArcadeGameUsers?token=${process.env.EDGE_CONFIG_TOKEN}`
  )
}
