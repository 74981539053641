const EyeNotIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '18', height = '18', fill = 'rgba(255,255,255,0.4)' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.85 13.2L13.925 11.3C14.275 10.5666 14.1709 9.99164 13.6125 9.57498C13.0542 9.15831 12.5334 9.10831 12.05 9.42498L10.3 7.64998C10.55 7.51664 10.825 7.41664 11.125 7.34998C11.425 7.28331 11.7167 7.24998 12 7.24998C13.1834 7.24998 14.1875 7.66248 15.0125 8.48748C15.8375 9.31248 16.25 10.3166 16.25 11.5C16.25 11.7666 16.2167 12.0666 16.15 12.4C16.0834 12.7333 15.9834 13 15.85 13.2ZM19.55 16.95L18.175 15.55C18.9084 14.9666 19.5459 14.325 20.0875 13.625C20.6292 12.925 21.0667 12.2166 21.4 11.5C20.5334 9.66664 19.2792 8.20831 17.6375 7.12498C15.9959 6.04164 14.2 5.49998 12.25 5.49998C11.55 5.49998 10.8667 5.55831 10.2 5.67498C9.53338 5.79164 9.04172 5.92498 8.72505 6.07498L7.07505 4.42498C7.65838 4.15831 8.42921 3.92498 9.38755 3.72498C10.3459 3.52498 11.2584 3.42498 12.125 3.42498C14.575 3.42498 16.8417 4.13331 18.925 5.54998C21.0084 6.96664 22.5584 8.94998 23.575 11.5C23.1584 12.6 22.5959 13.6125 21.8875 14.5375C21.1792 15.4625 20.4 16.2666 19.55 16.95ZM20.175 22.625L16.325 18.825C15.7417 19.0583 15.075 19.2416 14.325 19.375C13.575 19.5083 12.8 19.575 12 19.575C9.48338 19.575 7.17922 18.8625 5.08755 17.4375C2.99588 16.0125 1.44172 14.0333 0.425049 11.5C0.725049 10.65 1.18338 9.79164 1.80005 8.92498C2.41672 8.05831 3.13338 7.22498 3.95005 6.42498L0.875049 3.39998L2.12505 2.09998L21.35 21.325L20.175 22.625ZM5.40005 7.84998C4.80005 8.33331 4.25005 8.90414 3.75005 9.56248C3.25005 10.2208 2.86672 10.8666 2.60005 11.5C3.46672 13.35 4.74172 14.8125 6.42505 15.8875C8.10838 16.9625 10.0334 17.5 12.2 17.5C12.65 17.5 13.1125 17.475 13.5875 17.425C14.0625 17.375 14.4334 17.2833 14.7 17.15L13.1 15.55C12.9667 15.6166 12.7959 15.6666 12.5875 15.7C12.3792 15.7333 12.1834 15.75 12 15.75C10.8334 15.75 9.83338 15.3416 9.00005 14.525C8.16671 13.7083 7.75005 12.7 7.75005 11.5C7.75005 11.3166 7.76255 11.125 7.78755 10.925C7.81255 10.725 7.85005 10.55 7.90005 10.4L5.40005 7.84998Z"
      fill={fill}
    ></path>
  </svg>
)

export default EyeNotIcon
