const EyeIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '18', height = '21', fill = '#36B286' }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.875 6.46875C16.1875 2.625 12.8125 0 9 0C5.15625 0 1.78125 2.625 0.09375 6.46875C0.03125 6.625 0 6.84375 0 7C0 7.15625 0.03125 7.40625 0.09375 7.5625C1.78125 11.4062 5.15625 14 9 14C12.8125 14 16.1875 11.4062 17.875 7.5625C17.9375 7.40625 18 7.15625 18 7C18 6.84375 17.9375 6.625 17.875 6.46875ZM9 12.5C5.875 12.5 3 10.4062 1.5 7.03125C3.03125 3.625 5.875 1.5 9 1.5C12.0938 1.5 14.9688 3.625 16.4688 7C14.9375 10.4062 12.0938 12.5 9 12.5ZM9 3C6.78125 3 5 4.8125 5 7C5 9.21875 6.78125 11 9 11C11.1875 11 13 9.21875 13 7.03125C13 4.8125 11.1875 3 9 3ZM9 9.5C7.59375 9.5 6.5 8.40625 6.5 7C6.5 7 6.5 6.96875 6.5 6.9375C6.65625 7 6.8125 7 7 7C8.09375 7 9 6.125 9 5C9 4.84375 8.96875 4.6875 8.90625 4.53125C8.9375 4.53125 8.96875 4.5 9 4.5C10.375 4.5 11.5 5.625 11.5 7.03125C11.5 8.40625 10.375 9.5 9 9.5Z"
      fill={fill}
    />
  </svg>
)

export default EyeIcon
