import { useEffect, useReducer, useState } from 'react'
import {
  IDENTICAL_LETTERS,
  LOWERCASE_LETTERS,
  NUMBERS,
  SPECIAL_CHARACTERS,
  UPPERCASE_LETTERS,
} from 'constant/transaction'
import { getConfigByKeyName } from 'api/cdn.api'

type ActionType =
  | { type: 'identical'; payload: boolean }
  | { type: 'special_char'; payload: boolean }
  | { type: 'lower_case'; payload: boolean }
  | { type: 'upper_case'; payload: boolean }
  | { type: 'numbers'; payload: boolean }
  | { type: 'pwd_length'; payload: boolean }
  | { type: 'strength'; payload: 'Very Weak' | 'Weak' | 'Medium' | 'Strong' }
  | { type: 'reset' }

type PasswordValidationState = {
  identical: boolean
  special_char: boolean
  lower_case: boolean
  upper_case: boolean
  numbers: boolean
  pwd_length: boolean
  strength: string
}

export const passwordInitialState: PasswordValidationState = {
  identical: false,
  special_char: false,
  lower_case: false,
  upper_case: false,
  numbers: false,
  pwd_length: false,
  strength: '',
}

// Passwork strength list info update
export const passwordValidationReducer = (state: PasswordValidationState, action: ActionType) => {
  if (action.type === 'identical') {
    return { ...state, identical: action.payload }
  }
  if (action.type === 'special_char') {
    return { ...state, special_char: action.payload }
  }
  if (action.type === 'lower_case') {
    return { ...state, lower_case: action.payload }
  }
  if (action.type === 'upper_case') {
    return { ...state, upper_case: action.payload }
  }
  if (action.type === 'numbers') {
    return { ...state, numbers: action.payload }
  }
  if (action.type === 'pwd_length') {
    return { ...state, pwd_length: action.payload }
  }
  if (action.type === 'strength') {
    return { ...state, strength: action.payload }
  }
  if (action.type === 'reset') {
    return passwordInitialState
  }
  return passwordInitialState
}

type PasswordValidator = {
  isPasswordValid: () => boolean
  validatePassword: (value: string) => void
  validatePasswordPattern: (value: string) => string | undefined
  state: PasswordValidationState
}

export const usePasswordValidator: () => PasswordValidator = () => {
  const [state, dispatch] = useReducer(passwordValidationReducer, passwordInitialState)
  const [commonPasswordList, setCommonPasswordList] = useState<string[]>([])

  const fetchPaswordList = async () => {
    try {
      const resp = await getConfigByKeyName('CommonPasswordList')
      const passwordList = await resp.json()
      setCommonPasswordList(passwordList)
    } catch (e) {}
  }

  useEffect(() => {
    fetchPaswordList()
  }, [])

  const validatePasswordPattern = (inputString: string) => {
    const regex = /['"]/
    if (regex.test(inputString)) {
      return 'Invalid password. Please avoid using single or double quotes.'
    }
    if (commonPasswordList?.includes(inputString)) {
      return 'Avoid using common passwords or easily guessable information'
    }
    return undefined
  }

  const checkPasswordStrength = (password: string) => {
    if (password.length <= 4) {
      dispatch({ type: 'strength', payload: 'Very Weak' })
    } else if (password.length <= 6 && password.match(NUMBERS)) {
      dispatch({ type: 'strength', payload: 'Weak' })
    } else if (
      password.length <= 8 &&
      password.match(NUMBERS) &&
      password.match(SPECIAL_CHARACTERS)
    ) {
      dispatch({ type: 'strength', payload: 'Medium' })
    } else if (
      password.length <= 12 &&
      password.match(NUMBERS) &&
      password.match(SPECIAL_CHARACTERS) &&
      password.match(UPPERCASE_LETTERS)
    ) {
      dispatch({ type: 'strength', payload: 'Strong' })
    }
  }

  const validatePassword: (value: string) => void = (value) => {
    if (!value || value.match(IDENTICAL_LETTERS)) {
      dispatch({ type: 'identical', payload: false })
    } else {
      dispatch({ type: 'identical', payload: true })
    }

    if (value.match(LOWERCASE_LETTERS)) {
      dispatch({ type: 'lower_case', payload: true })
    } else {
      dispatch({ type: 'lower_case', payload: false })
    }

    if (value.match(UPPERCASE_LETTERS)) {
      dispatch({ type: 'upper_case', payload: true })
    } else {
      dispatch({ type: 'upper_case', payload: false })
    }

    if (value.match(SPECIAL_CHARACTERS)) {
      dispatch({ type: 'special_char', payload: true })
    } else {
      dispatch({ type: 'special_char', payload: false })
    }

    if (value.match(NUMBERS)) {
      dispatch({ type: 'numbers', payload: true })
    } else {
      dispatch({ type: 'numbers', payload: false })
    }

    if (value.length >= 8) {
      dispatch({ type: 'pwd_length', payload: true })
    } else {
      dispatch({ type: 'pwd_length', payload: false })
    }
    checkPasswordStrength(value)
  }

  const isPasswordValid: () => boolean = () => {
    return (
      state.identical &&
      state.special_char &&
      state.lower_case &&
      state.upper_case &&
      state.numbers &&
      state.pwd_length
    )
  }

  return {
    isPasswordValid,
    validatePassword,
    validatePasswordPattern,
    state,
  }
}
