import { FlexBox } from '@rario/shared-components'
import CricrushLogo from 'assets/svg/CricrushLogo'
import RarioLogo from 'assets/svg/RarioLogo'
import BackButton from 'components/BackButton/BackButton'
import { HeaderWrapper } from 'components/Header/Header.styles'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'
import { useEffect, useState } from 'react'
import { getAppWebViewDataFromLocalStorage } from 'utils/utils'

const LoginHeader = () => {
  const [statusBarHeight, setStatusBarHeight] = useState(24)
  const mobileAppBaseConfigData = getAppWebViewDataFromLocalStorage()

  useEffect(() => {
    if (mobileAppBaseConfigData?.statusbarHeight) {
      setStatusBarHeight(Number(mobileAppBaseConfigData.statusbarHeight))
    }
  }, [mobileAppBaseConfigData?.statusbarHeight])

  return (
    <FlexBox>
      <HeaderWrapper pt={statusBarHeight}>
        <FlexBox
          px={15}
          mx="auto"
          width="100%"
          height="45%"
          alignItems="center"
          position={'relative'}
          pb={20}
        >
          <BackButton />
          <FlexBox pl={2}>
            {PLATFORM_ORIGIN === ThemeEnum.RARIO ? <RarioLogo /> : <CricrushLogo />}
          </FlexBox>
        </FlexBox>
      </HeaderWrapper>
    </FlexBox>
  )
}

export default LoginHeader
