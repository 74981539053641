import { customStyled, FlexBox, theme, ThemeType } from '@rario/shared-components'

export const IconWrapper = customStyled(FlexBox)`
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
`

export const Form = customStyled.form`
    display: flex;
    flex-direction: column;
    width:100%;
`

export const Container = customStyled(FlexBox)<{
  isOpen?: boolean
  isBuyScreen?: boolean
  theme?: ThemeType
  isFromWithdrawModal?: boolean
}>`
    background: ${({ theme, isFromWithdrawModal = false }) =>
      isFromWithdrawModal ? theme.colors.black : theme.colors.chineseBlack};
    width: ${({ isBuyScreen = false }) => (isBuyScreen ? '614px' : '100%')};
    height: fit-content;
    border: 1px solid linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%, rgba(255, 255, 255, 0) 100%);
    border-radius: 5px;
    backdrop-filter: blur(60px);
    .titleBox{
        border-radius: 5px 5px 0 0;
        background: ${({ isOpen = false, theme }) =>
          isOpen ? theme.gradients.success : 'rgba(255, 255, 255, 0.04)'};
        transition: background .4s;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
        width: 100%;
    }
    .customText{
        color: ${({ theme }) => theme.colors.white}
    }
    .infoApprox {
        opacity: 0.6;
        position: absolute;
        right: 0;
    }
`

export const InfoContainer = customStyled.div<{
  isBuyScreen?: boolean
  notEnoughBalance?: boolean
}>`   
    display: inline-block; 
    cursor: pointer;
    position: relative;
    & svg {
        opacity: 0.6;
    }  
    &:hover svg {
        opacity: 1;
    }  
     &:hover .tooltiptext {
        visibility: visible;
    }
     & .tooltiptext{
        visibility: hidden;
        cursor: auto;
        width: 344px;
        height: ${({ notEnoughBalance = false }) => (notEnoughBalance ? 'auto' : '106px')};
        padding: 20px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: ${({ notEnoughBalance = false }) =>
          notEnoughBalance ? 'flex-start' : 'center'};
 
        /* Position the tooltip text */
        position: absolute;
        z-index: 1;
        top:${({ notEnoughBalance = false }) => (notEnoughBalance ? '' : '-115px')};
        bottom:${({ notEnoughBalance = false }) => (notEnoughBalance ? '30px' : '')};
        right: ${({ notEnoughBalance = false }) => (notEnoughBalance ? '-163px' : '-160px')};
    }
    & .tooltiptext::after{
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
    }
`

export const CustomLabel = customStyled.label<{ theme?: ThemeType }>`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;
    display: flex;
    color: ${({ theme }) => theme.colors.whites[8]};

    &.custom {
        z-index: 999999;
        position: absolute;
        top: 0px;
        left: 30px;
        padding: 2px 5px;
        transform-origin: left top;
        transform: translateY(-50%);
        pointer-events: none;
        background: ${({ theme }) => theme.colors.richBlackDark};
        color: ${({ theme }) => theme.colors.whites[4]};
        opacity: 1;
    }
`

export const CustomInput = customStyled.input<{
  theme?: ThemeType
  customCard?: boolean
  customPadding?: string
}>`
    position: ${({ customCard = false }) => (customCard ? 'relative' : 'static')};
    align-items: flex-start;
    border: 1px solid ${({ theme }) => theme.colors.whites[4]};
    border-radius: 5px;
    padding: ${({ customPadding }) => customPadding ?? '11px 10px'};
    background: transparent;
    color: white;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    width: 100%;

    &::placeholder {
        color: ${({ theme }) => theme.colors.whites[6]};
        font-weight: 400;
    }
    &:not(:placeholder-shown) { border: 1px solid ${({ theme }) => theme.colors.whites[8]}; }
    &.error { border: 1px solid ${({ theme }) => theme.colors.atomicTangerine} !important; }

    &:-webkit-autofill {
        -webkit-background-clip: text;
    }
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0
    }
`

export const CustomInputContainer = customStyled(FlexBox)<{
  theme?: ThemeType
  isOnModal?: boolean
  textArea?: boolean
}>`
    position: relative;
    width: 100%;

    .customLabel {
        position: absolute;
        top: ${({ textArea = false }) => (textArea ? '25px' : '50%')};
        left: 0;
        padding: 2px 5px;
        margin-left: 0.5em;
        transition: .15s ease-out;
        transform-origin: left top;
        transform: translateY(-50%);
        pointer-events: none;
        background: ${({ theme, isOnModal = false }) =>
          isOnModal ? theme.colors.chineseBlack : theme.colors.richBlack};
        color: ${({ theme }) => theme.colors.whites[8]};
        border: none !important;
    }

    .customInput {
        transition: 0.15s ease-out;
        &::placeholder {
            opacity: 0;
        }
    }

    .customInput.error {
        // color: ${({ theme }) => theme.colors.atomicTangerine} !important;
        border-color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }

    .customInput.customError {
        color: ${({ theme }) => theme.colors.atomicTangerine} !important;
        border-color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }

    .customInput:focus {
        border-color: #fff;
        &::placeholder {
            opacity: 1;
        }
    }

    .customInput:focus + .customLabel {
        color: ${({ theme }) => theme.colors.whites[10]};
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }
    .customInput:disabled + .customLabel {
        color: ${({ theme }) => theme.colors.whites[5]} !important;
    }

    .customInput:disabled  {
        color: ${({ theme }) => theme.colors.whites[8]} !important;
    }

    .customInput:not(:placeholder-shown) + .customLabel {
        top: 0;
        transform: translateY(-50%) scale(0.9);
    }

    .customInput:focus + .customLabel.error {
        color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }

    .customInput:not(:placeholder-shown) + .customLabel.error {
         color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }

    .customInput:focus + .customLabel.customError {
        color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }
     
    .customInput:not(:placeholder-shown) + .customLabel.customError {
         color: ${({ theme }) => theme.colors.atomicTangerine} !important;
    }
`

export const PasswordStrengthWrapper = customStyled.ul`
    padding-left: 5px;
    margin-top: 5px;
`

export const PasswordStrengthItem = customStyled.li`
    list-style: disc;
    color: ${theme.colors.whites[7]};
    font-size: 9px;
    
`
