const CrossSmallIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '8', height = '6', fill = '#FF4F44' }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 10 10" fill="none">
    <path
      d="M9.47656 7.64844C9.83203 7.97656 9.83203 8.55078 9.47656 8.87891C9.3125 9.04297 9.09375 9.125 8.875 9.125C8.62891 9.125 8.41016 9.04297 8.24609 8.87891L5.375 6.00781L2.47656 8.87891C2.3125 9.04297 2.09375 9.125 1.875 9.125C1.62891 9.125 1.41016 9.04297 1.24609 8.87891C0.890625 8.55078 0.890625 7.97656 1.24609 7.64844L4.11719 4.75L1.24609 1.87891C0.890625 1.55078 0.890625 0.976562 1.24609 0.648438C1.57422 0.292969 2.14844 0.292969 2.47656 0.648438L5.375 3.51953L8.24609 0.648438C8.57422 0.292969 9.14844 0.292969 9.47656 0.648438C9.83203 0.976562 9.83203 1.55078 9.47656 1.87891L6.60547 4.77734L9.47656 7.64844Z"
      fill={fill}
    ></path>
  </svg>
)

export default CrossSmallIcon
