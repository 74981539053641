import React, { FC } from 'react'
import { FlexBox, TextField, theme } from '@rario/shared-components'
import { PasswordStrengthItem, PasswordStrengthWrapper } from './LoginSignupForm.style'
import CrossSmallIcon from 'assets/svg/CrossSmallIcon'
import TickSmallIcon from 'assets/svg/TickSmallIcon'
import { passwordValidationTypes } from 'interfaces'

const PasswordStrengthList: FC<{ state: passwordValidationTypes }> = ({ state }) => {
  return (
    <>
      <FlexBox
        className="password-requirements"
        flexDirection={'column'}
        px={4}
        py={4}
        background={theme.colors.graySectionBG}
        borderRadius={12}
        mt={15}
      >
        <FlexBox justifyContent={'space-between'} width="100%">
          <TextField fontWeight={500} fontSize={'12px'} lineHeight={'16px'} pb={3}>
            Password Requirements
          </TextField>
          {!!state.strength.length && (
            <FlexBox
              background={
                state.strength === 'Strong'
                  ? theme.colors.graySmallSectionBg
                  : theme.colors.redSmallSectionBg
              }
              px={3}
              // py={1}
              borderRadius={100}
              justifyContent={'center'}
              alignItems={'center'}
              height={22}
            >
              <TextField fontWeight={400} fontSize={'12px'} lineHeight={'14px'} pb={0}>
                Strength
              </TextField>
              <FlexBox
                width={5}
                height={5}
                borderRadius={'50%'}
                background={theme.colors.white}
                mx={1}
              />
              <TextField
                color={state.strength === 'Strong' ? theme.colors.primary : theme.colors.secondary}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'14px'}
                pb={0}
              >
                {state.strength}
              </TextField>
            </FlexBox>
          )}
        </FlexBox>
        <PasswordStrengthWrapper>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must have minimum 8 characters in length
              </TextField>
              {state.pwd_length ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must contain lower case letters (a-z)
              </TextField>
              {state.lower_case ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must contain upper case letters (A-Z)
              </TextField>
              {state.upper_case ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must contain numbers (i.e. 0-9)
              </TextField>
              {state.numbers ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must contain special characters {'(e.g. !@#$%^&*)'}
              </TextField>
              {state.special_char ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
          <PasswordStrengthItem>
            <FlexBox justifyContent={'space-between'} width="100%" alignItems={'baseline'}>
              <TextField
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'16px'}
                pb={0}
                color={theme.colors.whites[7]}
              >
                Must contain only two consecutive identical characters. {'(e.g. Aa)'}
              </TextField>
              {state.identical ? <TickSmallIcon /> : <CrossSmallIcon />}
            </FlexBox>
          </PasswordStrengthItem>
        </PasswordStrengthWrapper>
      </FlexBox>
    </>
  )
}

export default PasswordStrengthList
