const TickSmallIcon: React.FunctionComponent<{
  className?: string
  width?: string
  height?: string
  fill?: string
}> = ({ className = '', width = '8', height = '6', fill = '#36B286' }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 14 10" fill="none">
    <path
      d="M13.125 1.25C13.125 1.49609 13.0156 1.71484 12.8516 1.87891L5.85156 8.87891C5.6875 9.04297 5.46875 9.125 5.25 9.125C5.00391 9.125 4.78516 9.04297 4.62109 8.87891L1.12109 5.37891C0.957031 5.21484 0.875 4.99609 0.875 4.75C0.875 4.25781 1.25781 3.875 1.75 3.875C1.96875 3.875 2.1875 3.98438 2.35156 4.14844L5.25 7.01953L11.6211 0.648438C11.7852 0.484375 12.0039 0.375 12.25 0.375C12.7148 0.375 13.125 0.757812 13.125 1.25Z"
      fill={fill}
    ></path>
  </svg>
)

export default TickSmallIcon
